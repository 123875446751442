import AppImage from '@theme/components/atom/AppImage'
import AppLink from '@theme/components/atom/AppLink'
import AppTitle from '@theme/components/atom/AppTitle'
import HeadingLevel from '~/mixins/HeadingLevel'

export default {
  components: {
    AppImage,
    AppLink,
    AppTitle,
  },
  mixins: [HeadingLevel],
  props: {
    banner: Object,
    reverseOrder: false,
  },
  data() {
    return {
      imageWidth: 320 * 1.05,
      imageHeight: 320 * 1.05,
    }
  },
}
